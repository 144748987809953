import { useEffect, useState } from 'react';
import {
    FlexRow,
    FormPrimaryContainer,
    FormSecondaryHeading,
    HighlightedText
} from '../../../styles';

import { useTranslation } from 'react-i18next';
import { constantStrings } from '../../../../constantStrings';
import MapBoxComponent from '../../../DesignSystem/MapBox';
import { colors } from '../../constants';
import { buildingDataTypes, markersDataType } from '../../types';

const mapBoxInitialValues = {
    center: [-95.7129, 37.0902],
    defaultZoom: 2,
    minZoom: 2
};

const BBBuildingsComponent = ({ filteredBuildingData }: any) => {
    const { t } = useTranslation();

    const uniqueBuildingsData = filteredBuildingData.buildingData.reduce(
        (arr: any, current: any) => {
            const index = arr?.findIndex((item: any) => item.buildingName === current.buildingName);
            if (index === -1) arr.push(current);
            return arr;
        },
        []
    );

    const [markers, setMarkers] = useState<markersDataType[] | []>([]);
    useEffect(() => {
        const markersData: markersDataType[] = uniqueBuildingsData
            .filter(
                (building: any) =>
                    building.latitude && building.privacyStatus === 'Non-Confidential'
            )
            .map((marker: buildingDataTypes, index: number) => ({
                id: index,
                latitude: Number(marker.latitude),
                longitude: Number(marker.longitude),
                buildingName: marker.buildingName,
                assetTypeName: marker.assetTypeName,
                buildingAddress: `${marker.addressLane1}${
                    marker.addressLane2 ? `, ${marker.addressLane2}` : ''
                }`,
                managementCompany: marker.managementCompanyName,
                certifications: [
                    {
                        certificationName: marker.certificationName,
                        certificationLevel: marker.certificationLevel,
                        certificationDate: marker.certificationIssueDate,
                        expiryDate: marker.certificationExpiryDate
                    }
                ]
            }))
            .reduce((acc: any[], curr: any) => {
                const existingData = acc.find((item) => item.id === curr.id);
                if (existingData) {
                    existingData.certifications.push(...curr.certifications);
                } else {
                    acc.push(curr);
                }
                return acc;
            }, []);

        setMarkers(markersData);
        //eslint-disable-next-line
    }, [filteredBuildingData]);

    return (
        <FormPrimaryContainer className='flex-fill col-12 h-100' border={true}>
            <FormSecondaryHeading>{t(constantStrings.bbBuildings)}</FormSecondaryHeading>
            <MapBoxComponent
                mapBoxInitialValues={mapBoxInitialValues}
                markersData={markers}
                mapID={'dashboardmap'}
            />
            <FlexRow className='flex-wrap mt-2'>
                {Object.keys(colors)
                    .sort()
                    .map((key: string) => (
                        <FlexRow className='align-items-center gap-1 me-4' key={key}>
                            <FlexRow
                                style={{
                                    width: '12px',
                                    height: '12px',
                                    borderRadius: '50%',
                                    backgroundColor: colors[key],
                                    border: '2px solid white',
                                    cursor: 'pointer'
                                }}
                            />
                            <HighlightedText className='text-muted'>{key}</HighlightedText>
                        </FlexRow>
                    ))}
            </FlexRow>
        </FormPrimaryContainer>
    );
};

export default BBBuildingsComponent;
