import React from 'react';
import { FormHeading, FormPrimaryContainer } from '../../../styles';
import GenericHeadingText from '../../../UIComponents/GenericHeading';
import { useTranslation } from 'react-i18next';
import { constantStrings } from '../../../../constantStrings';
import { buildingDataTypes } from '../../types';
import BarCharts from '../../../Recharts/BarCharts';
import { START_YEAR_FOR_CERTIFICATION_AWARDED } from '../../constants';

const CertificationAwardedComponent = ({ filteredBuildingData }: any) => {
    const { t } = useTranslation();

    const certificationAwarded = filteredBuildingData.buildingData.filter(
        (buildingData: buildingDataTypes) =>
            new Date(buildingData.certificationIssueDate).getFullYear() >=
                START_YEAR_FOR_CERTIFICATION_AWARDED &&
            new Date(buildingData.certificationIssueDate).getFullYear()
    );

    const getActiveCertificationsForYear = (year: number) => {
        return certificationAwarded.filter((buildingdata: buildingDataTypes) => {
            const issueYear = new Date(buildingdata.certificationIssueDate).getFullYear();
            const expiryYear = new Date(buildingdata.certificationExpiryDate).getFullYear();
            return year >= issueYear && year <= expiryYear;
        }).length;
    };

    const activeCertificationsperyear = Array.from(
        { length: new Date().getFullYear() - START_YEAR_FOR_CERTIFICATION_AWARDED },
        (_, index) => {
            const year = START_YEAR_FOR_CERTIFICATION_AWARDED + index;
            return {
                name: year,
                value: getActiveCertificationsForYear(year)
            };
        }
    );

    const showData = () => {
        if (!certificationAwarded.length)
            return (
                <GenericHeadingText
                    title={t(constantStrings.noInformationAvailable)}
                    className='col-3 mb-1'
                />
            );

        return (
            <BarCharts
                textlimit={true}
                barChartData={activeCertificationsperyear}
                yAxisLabel={t(constantStrings.countOfActiveCertificates)}
                xAxisInterval={0}
                className={'p-3'}
                containerHeight={450}
            />
        );
    };

    return (
        <FormPrimaryContainer className='flex-fill col-12 h-100' border={true}>
            <FormHeading>{t(constantStrings.activeCertificatesPerYear)}</FormHeading>
            {showData()}
        </FormPrimaryContainer>
    );
};

export default CertificationAwardedComponent;
