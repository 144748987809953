import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import englishTranslations from '../translations/en.json';
import frenchTranslations from '../translations/fr.json';
import { languageCodes } from './constants';

const languageTranslations = {
    [languageCodes.english]: englishTranslations,
    [languageCodes.french]: frenchTranslations
};

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: (languages: any) => {
                return `https://d25x3xotfq1jaq.cloudfront.net/PRODUCTION/FE/${languages[0]}/translations.json`;
            },
            request: (options: any, url: string, payload: any, callback: any) => {
                fetch(url)
                    .then((res) => res.json())
                    .then((result) => {
                        callback(null, {
                            data: result,
                            status: 200
                        });
                    })
                    .catch((err) => {
                        //@ts-ignore
                        callback(null, { data: languageTranslations[i18n.language], status: 200 });
                    });
            }
        },
        lng: languageCodes.english,
        fallbackLng: languageCodes.english,
        debug: false,
        interpolation: {
            escapeValue: false
        },
        nsSeparator: false,
        keySeparator: false,
        compatibilityJSON: 'v3'
    });

export default i18n;
/* 
To extract static strings from all ts,tsx files we used i18next-parser package which will generate a folder with translations.json file
To do so follow the steps
1) Install i18next-parser with command in CLI "npm install -g i18next-parser"
2) Create a file with name i18next-parser.config.js in src folder and add below content to the file
3) module.exports = {
    createOldCatalogs: true,
    lexers: {
        js: ['JsxLexer'],
        default: ['JavascriptLexer']
    },
    locales: ['es', 'en', 'arab', 'zh'],
    namespaceSeparator: false,
    keySeparator: false,
    output: './locales/{{lng}}/{{ns}}.json',
    format: 'json'
};
4) Now run the command in CLI "i18next 'src/**//*.{js,jsx,ts,tsx}' 'lib/**//*.{js,jsx,ts,tsx}' -c src/i18next-parser.config.js"
5) Then it will successfully creates locale folder with translations.json file which consists of all static strings which has t function (ex.t("Hello World")) 
*/
