import { all, put, takeLatest } from 'redux-saga/effects';
import {
    certificationActionTypes,
    CREATE_CERTIFICATION_LEVEL_DATA,
    CREATE_CERTIFICATION_TYPE,
    GET_CERTIFICATION_TYPES_LIST,
    GET_CERTIFICATION_LEVEL_LIST,
    GET_CERTIFICATION_LEVEL_OPTIONS,
    getCertificationLevelListAction,
    EDIT_CERTIFICATION_LEVEL_DATA,
    editCertificationLevelDataAction,
    createCertificationLevelDataAction,
    GET_CERTIFICATION_BUILDINGS_LIST,
    getCertificationBuildingsListAction,
    getCertificationTypesListAction
} from '../actions/Certification/types';
import {
    createCertificationLevelDataSuccess,
    createCertificationTypeSuccess,
    getCertificationTypesListData,
    getCertificationTypesListDataSuccess,
    getCertificationLevelListDataSuccess,
    getCertificationLevelOptionsDataSuccess,
    editCertificationLevelDataSuccess,
    getCertificationLevelListData,
    getCertificationBuildingsListSuccess
} from '../actions';
import { api_prefix } from '../../config';
import { getApiData, optionsApiData, patchApiData, postApiData } from '../../CommonUtilities';
import i18n from 'i18next';
import { constantStrings } from '../../constantStrings';

function* createCertificationTypeSaga(action: certificationActionTypes): any {
    const json = yield postApiData(
        `${api_prefix}/certification/`,
        action,
        i18n.t(constantStrings.certificationTypeAdded),
        true
    );
    const params: any = {};
    if (!json.data) {
        yield put(createCertificationTypeSuccess());
        yield put(getCertificationTypesListData(params));
    }
}

export function* certificationTypeSaga() {
    yield all([takeLatest(CREATE_CERTIFICATION_TYPE, createCertificationTypeSaga)]);
}

function* getCertificationTypesListSaga(action: getCertificationTypesListAction): any {
    const json = yield getApiData(`${api_prefix}/certification/`, action);
    if (json && json.results) {
        yield put(getCertificationTypesListDataSuccess(json, action.shouldAppend));
    }
}

export function* getCertificationTypesSaga() {
    yield all([takeLatest(GET_CERTIFICATION_TYPES_LIST, getCertificationTypesListSaga)]);
}

function* createCertificationLevelDataSaga(action: createCertificationLevelDataAction): any {
    const json = yield postApiData(
        `${api_prefix}/certification-level/`,
        action,
        i18n.t('Certification level data added successfully'),
        true
    );
    const params: any = {};
    if (!json.data) {
        yield put(createCertificationLevelDataSuccess());
        yield put(getCertificationLevelListData(params, action.id));
    }
}

export function* certificationLevelDataSaga() {
    yield all([takeLatest(CREATE_CERTIFICATION_LEVEL_DATA, createCertificationLevelDataSaga)]);
}

function* editCertificationLevelDataSaga(action: editCertificationLevelDataAction): any {
    const json = yield patchApiData(
        `${api_prefix}/certification-level/${action.certification_id}/`,
        action,
        i18n.t(constantStrings.certificationdataEdited),
        true
    );
    const params: any = {};
    if (!json.data) {
        yield put(editCertificationLevelDataSuccess());
        yield put(getCertificationLevelListData(params, action.id));
    }
}

export function* editCertificationLeveldataSaga() {
    yield all([takeLatest(EDIT_CERTIFICATION_LEVEL_DATA, editCertificationLevelDataSaga)]);
}

function* getCertificationLevelListSaga(action: getCertificationLevelListAction): any {
    const json = yield getApiData(
        `${api_prefix}/certification-level/?certification_id=${action.certification_id}`,
        action
    );
    if (json && json.results) {
        yield put(getCertificationLevelListDataSuccess(json));
    }
}

export function* getCertificationLevelSaga() {
    yield all([takeLatest(GET_CERTIFICATION_LEVEL_LIST, getCertificationLevelListSaga)]);
}

function* getCertificationLevelOptionsSaga(action: certificationActionTypes): any {
    const json = yield optionsApiData(`${api_prefix}/certification-level/`, action);
    if (json && json.name && json.actions && json.actions.POST) {
        yield put(getCertificationLevelOptionsDataSuccess(json.actions.POST));
    }
}

export function* getCertificationOptionsSaga() {
    yield all([takeLatest(GET_CERTIFICATION_LEVEL_OPTIONS, getCertificationLevelOptionsSaga)]);
}

function* getCertificationBuildingsListSaga(action: getCertificationBuildingsListAction): any {
    const json = yield getApiData(
        `${api_prefix}/certification-portfolios/?certification_name=${action.name}`,
        action
    );
    if (json && json.results) {
        yield put(getCertificationBuildingsListSuccess(json, action.shouldAppend));
    }
}

export function* getCertificationBuildingsSaga() {
    yield all([takeLatest(GET_CERTIFICATION_BUILDINGS_LIST, getCertificationBuildingsListSaga)]);
}
