import styled from 'styled-components';
import { fontSize_XS, fontSize_SM, fontSize_BASE } from '../../customFonts';
import { fontMedium } from '../../styles';

export const lightGreyShade = '#EBEBEB';
export const tableActionColor = '#f4f7fc';

interface Props {
    isBorderNone?: boolean;
    truncatedText?: boolean;
}

export const CustomTable = styled.div<Props>`
    background-color: ${(props) => props.theme.standard.WHITE};
    border: ${(props: any) =>
        props.isBorderNone ? 'none' : `solid 1px ${props.theme.light_primary.LIGHT_QUATERNARY}}`};
    border-radius: 12px;
    box-shadow: 0px 8px 16px rgba(44, 40, 40, 0.08);

    table {
        flex: 1;
        overflow: hidden;
        height: 100%;
        border-spacing: 0;
        border: 0;
        font-size: 16px;
        overflow-x: auto;
        width: 100%;

        /* tr {
      th:first-child,
      td:first-child{
        position: sticky;
        left: 0;
        background-color: white;
      }

      th:nth-child(2),
      td:nth-child(2) {
        position: sticky;
        left: 50px;
        background-color: white;
      }
    } */

        thead,
        tbody {
            th,
            td {
                padding: 0.8rem 1rem;
                color: #45445c;
                ${fontSize_SM};
                letter-spacing: 0px;
                font-weight: 500;
                border-bottom: solid 1px ${lightGreyShade};
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                border: solid 1px #eee;

                &.sticky-left {
                    position: sticky;
                    left: 0;
                    top: 0;
                    background-color: white;
                    z-index: 1;
                }

                &.sticky-right {
                    position: sticky;
                    right: 0;
                    top: 0;
                    background-color: white;
                }
            }
        }

        thead {
            background-color: white;
            position: sticky;
            top: -1px;
            background-color: ${(props: any) => props.theme.warning.LIGHT_WARNING};
            z-index: 99;

            th,
            i {
                color: ${(props) => props.theme.primary.SECONDARY_THEME};
            }

            th {
                img {
                    padding: 6px;
                    cursor: pointer;
                    border: solid 1px #ccc;
                    border-radius: 4px;
                    background-color: white;
                }

                b {
                    padding-right: 1em;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .tooltip::before,
                .tooltip::after {
                    display: none;
                }
            }
        }

        tbody {
            overflow-y: auto;

            tr {
                td {
                    div {
                        display: ${(props: any) => (props.truncatedText ? 'inline-block' : '')};
                        max-width: ${(props: any) => (props.truncatedText ? '250px' : 'none')};
                        overflow: hidden;
                        cursor: pointer;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    input {
                        height: 32px;
                        background-color: ${(props) => props.theme.standard.WHITE};
                        border: solid 1px ${(props) => props.theme.light_primary.LIGHT_PRIMARY};
                        border-radius: 8px;
                        padding: 5px 10px;
                        ${fontMedium}
                        padding-right: 10px;
                        font-size: 1rem;
                        width: 100%;
                        color: ${(props: any) => props.theme.primary.primaryHeadingFontColor};
                        appearance: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        box-shadow: 0px 4px 16px #0000001a;
                        opacity: 1;

                        &::placeholder {
                            color: ${(props) => props.theme.primary.inputPlaceholderColor};
                            opacity: 1;
                        }

                        &:focus-visible {
                            outline: none;
                        }

                        &:hover,
                        &:focus {
                            border: solid 1px ${(props: any) => props.theme.primary.THEME};
                        }
                    }
                }

                &:hover {
                    background-color: ${(props: any) => props.theme.warning.LIGHT_WARNING};
                    cursor: pointer;
                }
            }

            tr:nth-child(odd) {
                background-color: ${tableActionColor};

                &:hover {
                    background-color: ${(props: any) => props.theme.warning.LIGHT_WARNING};
                }
            }
        }
    }
`;

export const SecondaryCustomTable = styled(CustomTable)`
    table {
        tbody {
            th,
            td {
                overflow: unset;
            }
        }
        table thead tr th {
            background-color: ${(props: any) => props.theme.warning.LIGHT_WARNING};
            .postive {
                background-color: ${(props) => props.theme.success.DARK_SUCCESS};
            }
            .negative {
                background-color: ${(props) => props.theme.danger.DARK_DANGER};
            }
        }
    }
`;

export const BoxTableModal = styled.div`
  box-shadow: 0px 2px 10px #ccc;
  background-color: white;
  width: auto;
  padding: 12px;
  border-radius: 10px;

  .section-header {
    display: flex;
    justify-content: space-between;

    h5 {
      ${fontSize_BASE};
      color: ${(props: any) => props.theme.primary.SECONDARY_THEME};
      font-weight: bold;
    }

    i {
      color: ${(props: any) => props.theme.primary.THEME};
      cursor: pointer;
    }
  }

  .section-body {
    .asc-desc-btn-wrapper{
    button {
      b{
        color: black;
        font-weight: normal;
      }
    }  
  }
}



    h5 {
      ${fontSize_BASE};
      font-weight: bold;
    }

    .asc-desc-btn-wrapper {
      justify-content: space-between;
      display: flex;
      margin-bottom: 1.5rem;

      .asc-order {
        margin-right: 1rem;
      }

      .asc-order,
      .desc-order {
        border: solid;
        flex: 1;
        justify-content: center;
        border: 1px solid #bababa;
        border-radius: 4px;
        padding: 2px 5px;

        img {
          padding-right: 10px;
        }

        b {
          color: #3f4650;
          ${fontSize_XS};
          font-weight: 400;
          overflow: unset !important;
        }
      }
    }
  }

  .section-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
  }
`;
