import { put, takeLatest } from 'redux-saga/effects';

import {
    LOGIN,
    loginAction,
    forgotPasswordAction,
    FORGOT_PASSWORD,
    RESET,
    resetAction,
    UPDATE_PASSWORD,
    updatePasswordAction,
    GET_MULTITENANT_CONFIG,
    getMultiTenantConfigAction,
    LOGOUT
} from '../actions/Login/types';

import {
    forgotPasswordRequestFailure,
    forgotPasswordRequestSuccess,
    getLoginFailed,
    getMultiTenantConfigReceived,
    loginJwtReceived,
    logoutFailure,
    logoutSuccess,
    resetPasswordSuccess
} from '../actions';

import { api_prefix } from '../../config';
import { postApiData, patchApiData, getApiData } from '../../CommonUtilities';
import i18n from 'i18next';
import { constantStrings } from '../../constantStrings';
import { excludedErrorStatusCodes } from '../../constants';

function* getlogin(action: loginAction): any {
    const json = yield postApiData(
        `${api_prefix}/users/login/`,
        action,
        i18n.t(constantStrings.loginSuccess),
        true,
        false,
        excludedErrorStatusCodes
    );
    if (!json.data) {
        yield put(loginJwtReceived(json));
    } else {
        yield put(getLoginFailed(json));
    }
}

export function* loginUserSaga() {
    yield takeLatest(LOGIN, getlogin);
}

function* getLogout(action: loginAction): any {
    const json = yield postApiData(
        `${api_prefix}/users/logout/`,
        action,
        i18n.t(constantStrings.logoutSuccess),
        false
    );
    if (!json.data) {
        yield put(logoutSuccess());
    } else {
        yield put(logoutFailure());
    }
}

export function* logoutUserSaga() {
    yield takeLatest(LOGOUT, getLogout);
}

function* forgotPasswordRequest(action: forgotPasswordAction): any {
    const json = yield postApiData(`${api_prefix}/users/reset_password/`, action, '', false);

    if (json && json.success) {
        yield put(forgotPasswordRequestSuccess());
    } else {
        yield put(forgotPasswordRequestFailure());
    }
}

export function* forgotPasswordRequestSaga() {
    yield takeLatest(FORGOT_PASSWORD, forgotPasswordRequest);
}

function* resetPassword(action: resetAction): any {
    const json = yield postApiData(
        `${api_prefix}/users/update-password/`,
        action,
        i18n.t(constantStrings.passwordResetSuccess),
        true
    );

    if (json && json.success) {
        yield put(resetPasswordSuccess());
    }
}

export function* resetPasswordSaga() {
    yield takeLatest(RESET, resetPassword);
}

function* updatePassword(action: updatePasswordAction): any {
    yield patchApiData(
        `${api_prefix}/users/change-password/`,
        action,
        i18n.t(constantStrings.passwordUpdated),
        true,
        true
    );
}

export function* updatePasswordSaga() {
    yield takeLatest(UPDATE_PASSWORD, updatePassword);
}

function* getMultiTenantConfig(action: getMultiTenantConfigAction): any {
    const json = yield getApiData(`${api_prefix}/multi-tenant-config/`, action);
    if (json.results) {
        yield put(getMultiTenantConfigReceived(json.results));
    }
}

export function* getMultiTenantConfigSaga() {
    yield takeLatest(GET_MULTITENANT_CONFIG, getMultiTenantConfig);
}
