import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AscendingImg, DescendingImg } from '../../../assets';
import { constantStrings } from '../../../constantStrings';
import Button from '../Button';
import FormCheckBox from '../FormElements/FormCheckBox';
import FormDateTimePicker from '../FormElements/FormDateAndTimePicker';
import FormInput from '../FormElements/FormInput';
import SearchFilter from '../SearchFilter/SearchFilter';
import { Scrollable } from '../styles';
import { BoxTableModal } from './styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import CheckboxOptionsLoader from '../SkeletonLoader/CheckboxOptionsLoader';
import { useDebounce } from '../../../commonHooks';

export const TableFilterComponent = (props: any) => {
    const {
        id,
        filterValues,
        sortBy,
        setSortBy,
        setShowOverlay,
        sortState,
        setSortState,
        activeFilter,
        setActiveFilter,
        filterData,
        setFilterData
    } = props;

    const [minRangeValue, setMinRangeValue] = useState<any>(0);
    const [maxRangeValue, setMaxRangeValue] = useState<any>(100);
    const [selectedFilterData, setSelectedFilterData] = useState<any>({ ...filterData });
    const [searchFilterValue, setSearchFilterValue] = useState('');
    const debouncedSearchValue = useDebounce(searchFilterValue);
    const [filterValueOptions, setFilterValueOptions] = useState({
        meta: { next: null, previous: null, count: 0 },
        results: filterValues?.options || []
    });

    const [isLoading, setisLoading] = useState(false);

    const { t } = useTranslation();
    const methods = useForm({});

    const fetchFilterData = useCallback(
        (offset, appendData = false) => {
            const params: any = {
                ordering: '-id',
                search: debouncedSearchValue,
                limit: 100,
                offset: offset,
                fields: filterValues?.extraParams?.fields
            };
            setisLoading(true);

            axios
                .get(`${filterValues?.extraParams?.filterApiUrl}`, { params })
                .then((response) => {
                    if (appendData) {
                        setFilterValueOptions((filterOptions: any) => ({
                            meta: response.data.meta,
                            results: [
                                ...filterOptions.results,
                                ...response.data.results.map(
                                    (obj: Record<string, string | number>) => ({
                                        value: obj[filterValues?.extraParams?.value],
                                        label: obj[filterValues?.extraParams?.label]
                                    })
                                )
                            ]
                        }));
                    } else {
                        setFilterValueOptions({
                            meta: response.data.meta,
                            results: [
                                ...response.data.results.map(
                                    (obj: Record<string, string | number>) => ({
                                        value: obj[filterValues?.extraParams?.value].toString(),
                                        label: obj[filterValues?.extraParams?.label]
                                    })
                                )
                            ]
                        });
                    }
                })
                .finally(() => setisLoading(false));
        },
        [debouncedSearchValue, filterValues]
    );

    useEffect(() => {
        if (filterValues?.extraParams?.filterApiUrl) {
            fetchFilterData(0);
        }
    }, [filterValues?.extraParams?.filterApiUrl, fetchFilterData]);

    const fetchMoreData = () => {
        const limit = filterValueOptions.results.length;
        if (filterValueOptions.meta.next) {
            fetchFilterData(limit, true);
        }
    };

    const onCheckboxClick = (e: any, filterKey?: any) => {
        const value = e.target.value;
        const key = e.target.ariaLabel;
        let filterArr = { ...selectedFilterData };

        if (e.target.checked) {
            filterArr[`${filterKey}`] = [
                ...Object.values(filterArr[`${filterKey}`] || {}),
                { [key]: value }
            ];
        } else {
            const index = filterArr[`${filterKey}`].findIndex((obj: any) => obj[key] === value);
            if (index !== -1) {
                filterArr[`${filterKey}`].splice(index, 1);
            }
        }
        setSelectedFilterData({ ...filterArr });
    };

    const onChangeFilter = (value: any, filterKey: any) => {
        setSelectedFilterData((prevData: any) => ({
            ...prevData,
            [`${filterKey}`]: [{ value }]
        }));
    };

    const clearFilter = (filterKey: any) => {
        if (filterKey) {
            const newFilter = { ...activeFilter };
            newFilter[filterKey] = false;
            setActiveFilter(newFilter);
            let filterArr = { ...selectedFilterData };
            if (filterKey?.startKey) delete filterArr[`${filterKey.startKey}`];
            if (filterKey?.endKey) delete filterArr[`${filterKey.endKey}`];
            delete filterArr[`${filterKey}`];
            setFilterData({ ...filterArr });
        }
        setSortState('');
        if (sortBy.find((state: any) => (state.id = id))) {
            setSortBy(sortBy.filter((state: any) => state.id !== id));
            // toggleSortBy(false, true);
        }
    };

    const applyFilter = (filterKey: any) => {
        if (filterKey) {
            const newFilter = { ...activeFilter };
            newFilter[filterKey?.startKey ?? filterKey] = true;
            setActiveFilter(newFilter);
            setFilterData({ ...selectedFilterData });
        }
        //Sort
        if (sortState) {
            if (sortBy.find((state: any) => state.id === id)) {
                setSortBy(
                    sortBy.map((state: any) =>
                        state.id === id ? { id, desc: sortState === 'descending' } : state
                    )
                );
            } else {
                setSortBy([...sortBy, { id, desc: sortState === 'descending' }]);
            }
        }
        // toggleSortBy(sortState === 'descending', true);
    };

    return (
        <BoxTableModal>
            <div className='section-header'>
                <h5>{t(constantStrings.sort)}</h5>
                <p>
                    <i className='fa fa-close' onClick={() => setShowOverlay(false)} />
                </p>
            </div>
            <div className='section-body'>
                {filterValues.showSort && (
                    <>
                        <div className='asc-desc-btn-wrapper'>
                            <Button
                                variant={sortState === 'ascending' ? 'primary' : 'secondary'}
                                onClick={() => {
                                    setSortState('ascending');
                                }}
                            >
                                <img src={AscendingImg} alt={`${t(constantStrings.ascending)}`} />
                                <b>{t(constantStrings.ascending)}</b>
                            </Button>
                            <Button
                                variant={sortState === 'descending' ? 'primary' : 'secondary'}
                                onClick={() => {
                                    setSortState('descending');
                                }}
                            >
                                <img src={DescendingImg} alt={`${t(constantStrings.descending)}`} />
                                <b>{t(constantStrings.descending)}</b>
                            </Button>
                        </div>
                    </>
                )}
                {(filterValues?.options || filterValues?.extraParams?.filterApiUrl) && (
                    <SearchFilter
                        placeholder={t(constantStrings.search)}
                        className='w-100'
                        searchFilterValue={searchFilterValue}
                        setSearchFilterValue={(value: any) => {
                            setSearchFilterValue(value);
                            if (filterValues?.options) {
                                const filteredData = filterValues.options.filter((obj: any) =>
                                    obj.label.toLowerCase().includes(value.toLowerCase())
                                );
                                setFilterValueOptions({
                                    meta: {
                                        next: null,
                                        previous: null,
                                        count: filteredData.length
                                    },
                                    results: filteredData
                                });
                            }
                        }}
                    />
                )}
                <h5>{t(constantStrings.filters)}</h5>
                {isLoading && !!!filterValueOptions?.results?.length && (
                    <CheckboxOptionsLoader count={4} />
                )}
                <FormProvider {...methods}>
                    {!!filterValueOptions?.results?.length && (
                        <Scrollable
                            id={`${filterValues?.filterKey}`}
                            style={{ height: '280px', overflowY: 'auto', overflowX: 'hidden' }}
                        >
                            <InfiniteScroll
                                dataLength={filterValueOptions?.results?.length || 0}
                                next={fetchMoreData}
                                loader={<CheckboxOptionsLoader count={4} />}
                                hasMore={!!filterValueOptions?.meta?.next}
                                scrollableTarget={filterValues?.filterKey}
                                style={{ overflowX: 'hidden' }}
                            >
                                <div
                                    onChange={(e: any) =>
                                        onCheckboxClick(e, filterValues?.filterKey)
                                    }
                                >
                                    <FormCheckBox
                                        name={'filterCheckBox' + id}
                                        checked={selectedFilterData[
                                            filterValues?.filterKey
                                        ]?.reduce((arr: any, value: any) => {
                                            return {
                                                ...arr,
                                                [Object.keys(value)?.[0]]: Object.values(value)?.[0]
                                            };
                                        }, {})}
                                        options={filterValueOptions?.results || []}
                                        asterisk={false}
                                        verticalDirection={true}
                                        noMargin
                                    />
                                </div>
                            </InfiniteScroll>
                        </Scrollable>
                    )}
                    {/* <Scrollable>
                  {filterValues.options && <FormRadio
                    name={'selectedFilterOptions'}
                    displayName={'Filter Options'}
                    options={getOptions()}
                    verticalDirection={true}
                  />}
                </Scrollable> */}

                    {filterValues.rangeValues && (
                        <div className='d-flex flex-column'>
                            <label htmlFor='minRangeFilter'>
                                {t(constantStrings.minValue)} {minRangeValue}
                            </label>
                            <input
                                type='range'
                                name='rangeFilter'
                                id='minRangeFilter'
                                min={filterValues.rangeValues.min}
                                max={maxRangeValue}
                                value={minRangeValue}
                                onChange={(e: any) => setMinRangeValue(e.target.value)}
                            />
                            <label htmlFor='maxRangeFilter'>
                                {t(constantStrings.maxValue)} {maxRangeValue}
                            </label>
                            <input
                                type='range'
                                name='rangeFilter'
                                id='maxRangeFilter'
                                min={minRangeValue}
                                max={filterValues.rangeValues.max}
                                value={maxRangeValue}
                                onChange={(e: any) => setMaxRangeValue(e.target.value)}
                            />
                        </div>
                    )}
                    {/* {filterValues.rageValues && (
                <div className='d-flex flex-column'>
                  <FormInput
                    name='minRangeValue'
                    type='number'
                    displayName='Minimum Value'
                    asterisk={false}
                  />
                  <FormInput
                    name='maxRangeValue'
                    type='number'
                    displayName='Maximum Value'
                    asterisk={false}
                  />
                </div>
              )} */}

                    {/* {filterValues.showSearch && <SearchFilter
                    placeholder={'Search Filter'}
                    searchFilterValue={searchFilterValue}
                    setSearchFilterValue={setSearchFilterValue}
                    className={'w-100'}
                  />} */}
                    {filterValues.showSearch && (
                        <FormInput
                            name={'searchFilterValue' + id}
                            type='text'
                            className='w-100'
                            defaultValue={selectedFilterData[filterValues?.filterKey]?.[0]?.value}
                            onChange={(e: any) =>
                                onChangeFilter(e.target.value, filterValues?.filterKey)
                            }
                        />
                    )}

                    {/* {filterValues.showDateRangeSelector && (
                    <div className='d-flex flex-column'>
                      <label htmlFor="fromDate">From Date:</label>
                      <input 
                        type="date" 
                        id="fromDate" 
                        name="fromDate" 
                        onChange={(e: any)=>setFromDateValue(e.target.value)} 
                        value={fromDateValue}/>
                      <label htmlFor="toDate">To Date:</label>
                      <input 
                        type="date" 
                        id="toDate"
                        name="toDate"
                        onChange={(e: any)=>setToDateValue(e.target.value)} 
                        value={toDateValue}/>
                    </div>
                  )} */}

                    {filterValues.showDateRangeSelector && (
                        <div className='d-flex flex-row'>
                            <FormDateTimePicker
                                name={`fromDate${id}`}
                                displayName={t(constantStrings.fromDate)}
                                asterisk={false}
                                defaultValue={
                                    selectedFilterData[filterValues?.filterKey?.startKey]?.[0]
                                        ?.value
                                        ? new Date(
                                              selectedFilterData[
                                                  filterValues?.filterKey?.startKey
                                              ]?.[0]?.value
                                          )
                                        : null
                                }
                                onChangeHandler={(e: any) =>
                                    onChangeFilter(
                                        format(new Date(e), 'yyyy-MM-dd'),
                                        filterValues.filterKey.startKey
                                    )
                                }
                                placeHolderText={t(constantStrings.datePlaceholder)}
                            />
                            <FormDateTimePicker
                                name={`toDate${id}`}
                                displayName={t(constantStrings.toDate)}
                                asterisk={false}
                                defaultValue={
                                    selectedFilterData[filterValues?.filterKey?.endKey]?.[0]?.value
                                        ? new Date(
                                              selectedFilterData[
                                                  filterValues?.filterKey?.endKey
                                              ]?.[0]?.value
                                          )
                                        : null
                                }
                                onChangeHandler={(e: any) =>
                                    onChangeFilter(
                                        format(new Date(e), 'yyyy-MM-dd'),
                                        filterValues.filterKey.endKey
                                    )
                                }
                                placeHolderText={t(constantStrings.datePlaceholder)}
                            />
                        </div>
                    )}
                    <div className='section-footer'>
                        <Button
                            variant='secondary'
                            onClick={() => {
                                setShowOverlay(false);
                                clearFilter(filterValues?.filterKey);
                            }}
                        >
                            {' '}
                            {t(constantStrings.clear)}
                        </Button>
                        <Button
                            variant='primary'
                            type='submit'
                            onClick={() => {
                                applyFilter(filterValues?.filterKey);
                                setShowOverlay(false);
                            }}
                        >
                            {' '}
                            {t(constantStrings.apply)}
                        </Button>
                    </div>
                </FormProvider>
            </div>
        </BoxTableModal>
    );
};
