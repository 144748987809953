import { put, takeLatest } from 'redux-saga/effects';
import { deleteApiData, getApiData, optionsApiData, postApiData } from '../../CommonUtilities';
import i18n from 'i18next';
import { api_prefix } from '../../config';
import { getCombinations, getQuestionnaire, getQuestionnaireReceived } from '../actions';
import {
    addAttachmentSuccess,
    createDraftSuccess,
    createQuestionSuccess,
    deleteCombinationSuccess,
    deleteQuestionSuccess,
    getAnswerQuestionnaireReceived,
    getAnswersReceived,
    getCombinationsReceived,
    getQuestionsOptionsSuccess,
    getQuestionValidationsSuccess,
    getSequenceId,
    getSequenceIdReceived,
    moveToArchiveSuccess,
    publishQuestionnaireSuccess,
    setQuestionnaireType,
    submitForVerificationSuccess,
    updateQuestionSuccess
} from '../actions/Questionnaire/index';
import {
    addAttachmentAction,
    ADD_ATTACHMENT,
    createDraftAction,
    createQuestionAction,
    CREATE_DRAFT,
    CREATE_QUESTION,
    deleteCombinationAction,
    deleteQuestionAction,
    DELETE_COMBINATION,
    DELETE_QUESTION,
    getAnswerQuestionnaireAction,
    getAnswersAction,
    getCombinationsAction,
    getQuestionnaireAction,
    getQuestionValidationsAction,
    getSequenceIdAction,
    GET_ANSWERS,
    GET_ANSWER_QUESTIONNAIRE,
    GET_COMBINATIONS,
    GET_QUESTIONNAIRE,
    GET_QUESTIONS_OPTIONS,
    GET_QUESTION_VALIDATIONS,
    GET_SEQUENCE_ID,
    MOVE_TO_ARCHIVE,
    publishQuestionnaireAction,
    PUBLISH_QUESTIONNAIRE,
    questionnaireActionTypes,
    submitForVerificationAction,
    SUBMIT_FOR_VERIFICATION,
    updateQuestionAction,
    UPDATE_QUESTION
} from '../actions/Questionnaire/types';
import { onErrorCallback } from './../../CommonUtilities';
import { constantStrings } from '../../constantStrings';

function* getQuestionnaireSaga(action: getQuestionnaireAction): any {
    const json = yield getApiData(
        `${api_prefix}/questonnaire-version/?status=${action.status}`,
        action
    );
    if (json && json.results) {
        yield put(getQuestionnaireReceived(json, action.appendData));
    }
}

export function* getQuestionnaireSagas() {
    yield takeLatest(GET_QUESTIONNAIRE, getQuestionnaireSaga);
}

function* getCombinationsSaga(action: getCombinationsAction): any {
    const json = yield getApiData(
        `${api_prefix}/questonnaire-version/${action.id}/get_questionnaire/`,
        action
    );
    if (!json.data) {
        yield put(getCombinationsReceived(json));
    }
}

export function* getCombinationsSagas() {
    yield takeLatest(GET_COMBINATIONS, getCombinationsSaga);
}

function* getQuestionsOptions(action: questionnaireActionTypes): any {
    const json = yield optionsApiData(`${api_prefix}/question/`, action);
    if (json && json.actions && json.actions.POST) {
        yield put(getQuestionsOptionsSuccess(json.actions.POST.question_type.choices));
    }
}

export function* getQuestionsOptionsSaga() {
    yield takeLatest(GET_QUESTIONS_OPTIONS, getQuestionsOptions);
}

function* createQuestion(action: createQuestionAction): any {
    try {
        const json = yield postApiData(
            `${api_prefix}/questionnaire-combinations/${action.id}/create_question/`,
            action,
            i18n.t(constantStrings.questionAdded),
            true
        );
        let params: any = {
            ordering: 'id'
        };
        if (json) {
            yield put(createQuestionSuccess());
            yield put(getCombinations(params, action.questionnaireId));
            yield put(getSequenceId(action.id));
        }
    } catch (error) {
        onErrorCallback(error);
    }
}

export function* createQuestionSaga() {
    yield takeLatest(CREATE_QUESTION, createQuestion);
}

function* updateQuestion(action: updateQuestionAction): any {
    try {
        const json = yield postApiData(
            `${api_prefix}/questionnaire-combinations/${action.id}/update_question/`,
            action,
            i18n.t(constantStrings.questionUpdated),
            true
        );
        let params: any = {
            ordering: 'id'
        };
        if (json) {
            yield put(updateQuestionSuccess());
            yield put(getCombinations(params, action.questionnaireId));
        }
    } catch (error) {
        onErrorCallback(error);
    }
}

export function* updateQuestionSaga() {
    yield takeLatest(UPDATE_QUESTION, updateQuestion);
}

function* deleteQuestion(action: deleteQuestionAction): any {
    const json = yield deleteApiData(
        `${api_prefix}/questionnaire-combinations/delete_question/`,
        action,
        i18n.t(constantStrings.questionDeleted)
    );
    let params: any = {
        ordering: 'id'
    };

    if (!json.data) {
        yield put(deleteQuestionSuccess());
        yield put(getCombinations(params, action.questionnaireId));
        yield put(getSequenceId(action.id));
    }
}

export function* deleteQuestionSaga() {
    yield takeLatest(DELETE_QUESTION, deleteQuestion);
}

function* deleteCombination(action: deleteCombinationAction): any {
    const json = yield deleteApiData(
        `${api_prefix}/questonnaire-version/delete_combination/`,
        action,
        `${action.name} ${i18n.t(constantStrings.deletedSuccess)}`
    );
    let params: any = {
        ordering: 'id'
    };

    if (!json.data) {
        yield put(deleteCombinationSuccess());
        yield put(getCombinations(params, action.questionnaireId));
    }
}

export function* deleteCombinationSaga() {
    yield takeLatest(DELETE_COMBINATION, deleteCombination);
}

function* getSequenceIdSaga(action: getSequenceIdAction): any {
    const json = yield getApiData(
        `${api_prefix}/questionnaire-combinations/${action.id}/get_sequence_id/`,
        action
    );
    if (json) {
        yield put(getSequenceIdReceived(json));
    }
}

export function* getSequenceIdSagas() {
    yield takeLatest(GET_SEQUENCE_ID, getSequenceIdSaga);
}

function* getAnswerQuestionnaire(action: getAnswerQuestionnaireAction): any {
    const json = yield getApiData(
        `${api_prefix}/answer-questionnaire/${action.id}/questionnaires_building/`,
        action
    );
    if (json) {
        yield put(getAnswerQuestionnaireReceived(json));
    }
}

export function* getAnswerQuestionnaireSaga() {
    yield takeLatest(GET_ANSWER_QUESTIONNAIRE, getAnswerQuestionnaire);
}

function* getAnswer(action: getAnswersAction): any {
    const json = yield getApiData(
        `${api_prefix}/answer-questionnaire/${action.id}/get_answers/`,
        action
    );
    if (json) {
        yield put(getAnswersReceived(json));
    }
}

export function* getAnswersSaga() {
    yield takeLatest(GET_ANSWERS, getAnswer);
}

function* publishQuestionnaire(action: publishQuestionnaireAction): any {
    const json = yield postApiData(
        `${api_prefix}/questonnaire-version/publish_questionnaire/`,
        action,
        i18n.t(constantStrings.newQuestionnairePublished),
        true
    );
    let params: any = {
        ordering: 'id',
        limit: action.limit,
        offset: 0
    };
    if (json.id) {
        yield put(publishQuestionnaireSuccess());
        yield put(setQuestionnaireType('active'));
        action.history.push('/Questionnaire');
    }
    yield put(getQuestionnaire(params, action.status));
}

export function* publishQuestionnaireSaga() {
    yield takeLatest(PUBLISH_QUESTIONNAIRE, publishQuestionnaire);
}

function* getQuestionValidations(action: getQuestionValidationsAction): any {
    const json = yield getApiData(`${api_prefix}/question-validations/`, action);
    if (json && json.results) {
        yield put(getQuestionValidationsSuccess(json));
    }
}

export function* getQuestionValidationsSaga() {
    yield takeLatest(GET_QUESTION_VALIDATIONS, getQuestionValidations);
}

function* submitForVerification(action: submitForVerificationAction): any {
    const json = yield postApiData(
        `${api_prefix}/answer-questionnaire/${action.id}/submit_for_verification/`,
        action,
        i18n.t(constantStrings.questionnaireSubmittedForVerification),
        true
    );

    if (!json.data) {
        yield put(submitForVerificationSuccess());
        action.history && action.history.goBack();
    }
}

export function* submitForVerificationSaga() {
    yield takeLatest(SUBMIT_FOR_VERIFICATION, submitForVerification);
}

function* createDraft(action: createDraftAction): any {
    const json = yield postApiData(
        `${api_prefix}/questonnaire-version/create_draft/`,
        action,
        i18n.t(constantStrings.draftCreated),
        true
    );
    let params: any = {
        ordering: 'id',
        limit: action.limit,
        offset: 0
    };
    if (json.id) {
        yield put(createDraftSuccess());
    }
    yield put(getQuestionnaire(params, action.status));
}

export function* createDraftSaga() {
    yield takeLatest(CREATE_DRAFT, createDraft);
}

function* moveToArchive(action: createQuestionAction): any {
    const json = yield postApiData(
        `${api_prefix}/questonnaire-version/move_to_archive/`,
        action,
        i18n.t(constantStrings.questionnaireMovedToArchive),
        true
    );

    if (json.id) {
        yield put(moveToArchiveSuccess());
    }
}

export function* moveToArchiveSaga() {
    yield takeLatest(MOVE_TO_ARCHIVE, moveToArchive);
}

function* addAttachment(action: addAttachmentAction): any {
    const json = yield postApiData(
        `${api_prefix}/attachments/`,
        action,
        i18n.t(constantStrings.attachmentAdded),
        true
    );
    if (json.id) {
        yield put(addAttachmentSuccess(json));
    }
}

export function* addAttachmentSaga() {
    yield takeLatest(ADD_ATTACHMENT, addAttachment);
}
