import { put, takeLatest } from 'redux-saga/effects';
import {
    getApiData,
    optionsApiData,
    patchApiData,
    postApiData,
    putApiData
} from '../../CommonUtilities';
import { api_prefix } from '../../config';
import {
    consumptionDataFailure,
    consumptionDataRecieved,
    createConsumptionDataSuccess,
    createEnergyStarPropertyDetailsSuccess,
    createMeterFailure,
    createMeterSuccess,
    createWasteConsumptionDataSuccess,
    createWastemeterFailure,
    createWastemeterSuccess,
    deliveryDataRecieved,
    editConsumptionDataFailure,
    editConsumptionDataSuccess,
    editDeliveryDataSuccess,
    editEnergyStarPropertyDetailsSuccess,
    editWasteConsumptionDataFailure,
    editWasteConsumptionDataSuccess,
    eGridOptionsRecieved,
    energyStarPropertyDetailsRecieved,
    getBuildingDetails,
    getConsumptionData,
    getDeliveryData,
    getMeterFailure,
    getMeters,
    getWasteConsumptionData,
    getWastemeters,
    meterOptionsRecieved,
    metersRecieved,
    propertyOptionsRecieved,
    wasteConsumptionDataFailure,
    wasteConsumptionDataRecieved,
    wasteMeterOptionsRecieved,
    wasteMeterRecieved
} from '../actions';
import {
    createConsumptionDataAction,
    createDeliveryDataAction,
    createEnergyStarPropertyDetailsAction,
    createMeterAction,
    createWasteConsumptionDataAction,
    createWastemeterAction,
    CREATE_CONSUMPTION_DATA,
    CREATE_DELIVERY_DATA,
    CREATE_ENERGY_STAR_PROPERTY_DETAILS,
    CREATE_METER,
    CREATE_WASTEMETER,
    CREATE_WASTE_CONSUMPTION_DATA,
    editConsumptionDataAction,
    editDeliveryDataAction,
    editEnergyStarPropertyDetailsAction,
    editWasteConsumptionDataAction,
    EDIT_CONSUMPTION_DATA,
    EDIT_DELIVERY_DATA,
    EDIT_ENERGY_STAR_PROPERTY_DETAILS,
    EDIT_WASTE_CONSUMPTION_DATA,
    getConsumptionDataAction,
    getDeliveryDataAction,
    getEGridOptionsAction,
    getEnergyStarPropertyDetailsAction,
    getMeterOptionsAction,
    getMetersAction,
    getPropertyOptionsAction,
    getWasteConsumptionDataAction,
    getWasteMeterOptionsAction,
    getWastemetersAction,
    GET_CONSUMPTION_DATA,
    GET_DELIVERY_DATA,
    GET_EGRID_OPTIONS,
    GET_ENERGY_STAR_PROPERTY_DETAILS,
    GET_METERS,
    GET_METER_OPTIONS,
    GET_PROPERTY_OPTIONS,
    GET_WASTEMETERS,
    GET_WASTEMETER_OPTIONS,
    GET_WASTE_CONSUMPTION_DATA
} from '../actions/Benchmarking/types';
import i18n from 'i18next';
import { constantStrings } from '../../constantStrings';

function* getEnergyStarPropertyDetails(action: getEnergyStarPropertyDetailsAction): any {
    const json = yield getApiData(`${api_prefix}/property/`, action);
    if (json) {
        yield put(energyStarPropertyDetailsRecieved(json));
    }
}

export function* getEnergyStarPropertyDetailsSaga() {
    yield takeLatest(GET_ENERGY_STAR_PROPERTY_DETAILS, getEnergyStarPropertyDetails);
}

function* createEnergyStarPropertyDetails(action: createEnergyStarPropertyDetailsAction): any {
    const json = yield postApiData(
        `${api_prefix}/property/`,
        action,
        i18n.t(constantStrings.energyStarAdded),
        true
    );
    if (json) {
        yield put(getBuildingDetails(action.params.building));
        yield put(createEnergyStarPropertyDetailsSuccess());
    }
}

export function* createEnergyStarPropertyDetailsSaga() {
    yield takeLatest(CREATE_ENERGY_STAR_PROPERTY_DETAILS, createEnergyStarPropertyDetails);
}

function* editEnergyStarPropertyDetails(action: editEnergyStarPropertyDetailsAction): any {
    const json = yield putApiData(
        `${api_prefix}/property/${action.id}/`,
        action,
        i18n.t(constantStrings.energyStarEdited),
        true
    );
    if (json) {
        yield put(getBuildingDetails(action.params.building));
        yield put(editEnergyStarPropertyDetailsSuccess());
    }
}

export function* editEnergyStarPropertyDetailsSaga() {
    yield takeLatest(EDIT_ENERGY_STAR_PROPERTY_DETAILS, editEnergyStarPropertyDetails);
}

function* getMetersData(action: getMetersAction): any {
    const json = yield getApiData(
        `${api_prefix}/meter/?property=${action.id}&meter_type=${action.meterType}`,
        action
    );
    if (json) yield put(metersRecieved(json));
    else yield put(getMeterFailure());
}

export function* getMetersSaga() {
    yield takeLatest(GET_METERS, getMetersData);
}

function* createMeterRequest(action: createMeterAction): any {
    const json = yield postApiData(
        `${api_prefix}/meter/`,
        action,
        i18n.t(constantStrings.meterAdded),
        true
    );
    if (json.data) {
        yield put(createMeterFailure());
    } else {
        yield put(createMeterSuccess());
    }

    yield put(getMeters(action.getMeterParams.property, action.getMeterParams.meterType));
}

export function* createMeterSaga() {
    yield takeLatest(CREATE_METER, createMeterRequest);
}

function* getWasteMeterDetails(action: getWastemetersAction): any {
    const json = yield getApiData(`${api_prefix}/wastemeter/?property=${action.id}`, action);
    if (json) yield put(wasteMeterRecieved(json));
}

export function* getWasteMeterDetailsSaga() {
    yield takeLatest(GET_WASTEMETERS, getWasteMeterDetails);
}

function* createWasteMeterDetails(action: createWastemeterAction): any {
    const json = yield postApiData(
        `${api_prefix}/wastemeter/`,
        action,
        i18n.t(constantStrings.wasteMeterAdded),
        true
    );
    if (json) {
        yield put(createWastemeterSuccess());
    } else {
        yield put(createWastemeterFailure());
    }
    yield put(getWastemeters(action.energyStarPropertyID));
}

export function* createWasteMeterDetailsSaga() {
    yield takeLatest(CREATE_WASTEMETER, createWasteMeterDetails);
}

function* getConsumptionDataDetails(action: getConsumptionDataAction): any {
    const json = yield getApiData(
        `${api_prefix}/consumption/?meter__in=${action.idArray?.toString()}&ordering=-id`,
        action
    );
    if (json) yield put(consumptionDataRecieved(json));
    else yield put(consumptionDataFailure());
}

export function* getConsumptionDataSaga() {
    yield takeLatest(GET_CONSUMPTION_DATA, getConsumptionDataDetails);
}

function* createConsumptionData(action: createConsumptionDataAction): any {
    yield postApiData(
        `${api_prefix}/consumption/`,
        action,
        i18n.t(constantStrings.meterDataAdded),
        true
    );
    yield put(createConsumptionDataSuccess());
    yield put(getConsumptionData(action.meters));
}

export function* createConsumptionDataSaga() {
    yield takeLatest(CREATE_CONSUMPTION_DATA, createConsumptionData);
}

function* editConsumptionData(action: editConsumptionDataAction): any {
    const json = yield patchApiData(
        `${api_prefix}/consumption/${action.id}/`,
        action,
        i18n.t(constantStrings.updateMeterDetails),
        true
    );
    if (json) {
        yield put(editConsumptionDataSuccess());
    } else {
        yield put(editConsumptionDataFailure());
    }
    yield put(getConsumptionData(action.meters));
}

export function* editConsumptionDataSaga() {
    yield takeLatest(EDIT_CONSUMPTION_DATA, editConsumptionData);
}

function* getDeliveryDataDetails(action: getDeliveryDataAction): any {
    const json = yield getApiData(
        `${api_prefix}/meterdelivery/?meter__in=${action.idArray?.toString()}&ordering=-id`,
        action
    );
    yield put(deliveryDataRecieved(json));
}

export function* getDeliveryDataSaga() {
    yield takeLatest(GET_DELIVERY_DATA, getDeliveryDataDetails);
}

function* createDeliveryData(action: createDeliveryDataAction): any {
    const json = yield postApiData(
        `${api_prefix}/meterdelivery/`,
        action,
        i18n.t(constantStrings.addedDeliveryData),
        true
    );
    if (json) {
        yield put(createConsumptionDataSuccess());
    }
    yield put(getDeliveryData(action.meters));
}

export function* createDeliveryDataSaga() {
    yield takeLatest(CREATE_DELIVERY_DATA, createDeliveryData);
}

function* editDeliveryData(action: editDeliveryDataAction): any {
    const json = yield patchApiData(
        `${api_prefix}/meterdelivery/${action.id}/`,
        action,
        i18n.t(constantStrings.editedDeliveryData),
        true
    );
    if (json) {
        yield put(editDeliveryDataSuccess());
    }
    yield put(getDeliveryData(action.meters));
}

export function* editDeliveryDataSaga() {
    yield takeLatest(EDIT_DELIVERY_DATA, editDeliveryData);
}

function* getWasteConsumptionDataDetails(action: getWasteConsumptionDataAction): any {
    const json = yield getApiData(
        `${api_prefix}/wasteconsumption/?meter__in=${action.idArray?.toString()}&ordering=-id`,
        action
    );
    if (json) yield put(wasteConsumptionDataRecieved(json));
    else yield put(wasteConsumptionDataFailure());
}

export function* getWasteConsumptionDataSaga() {
    yield takeLatest(GET_WASTE_CONSUMPTION_DATA, getWasteConsumptionDataDetails);
}

function* createWasteConsumptionData(action: createWasteConsumptionDataAction): any {
    yield postApiData(
        `${api_prefix}/wasteconsumption/`,
        action,
        i18n.t(constantStrings.meterDataAdded),
        true
    );
    yield put(createWasteConsumptionDataSuccess());
    yield put(getWasteConsumptionData(action.wasteMeters));
}

export function* createWasteConsumptionDataSaga() {
    yield takeLatest(CREATE_WASTE_CONSUMPTION_DATA, createWasteConsumptionData);
}

function* editWasteConsumptionData(action: editWasteConsumptionDataAction): any {
    const json = yield patchApiData(
        `${api_prefix}/wasteconsumption/${action.id}/`,
        action,
        i18n.t(constantStrings.editedSuccessfully),
        true
    );
    if (json) {
        yield put(editWasteConsumptionDataSuccess());
    } else yield put(editWasteConsumptionDataFailure());
    yield put(getWasteConsumptionData(action.wasteMeters));
}

export function* editWasteConsumptionDataSaga() {
    yield takeLatest(EDIT_WASTE_CONSUMPTION_DATA, editWasteConsumptionData);
}

function* getEGridOptionsData(action: getEGridOptionsAction): any {
    let eGridSubRegionOptions = yield getApiData(`${api_prefix}/egrid-subregions/`, action);
    eGridSubRegionOptions = eGridSubRegionOptions.results.map((elements: any) => ({
        label: elements.name,
        value: elements.id
    }));
    yield put(eGridOptionsRecieved(eGridSubRegionOptions));
}

export function* getEGridOptionsSaga() {
    yield takeLatest(GET_EGRID_OPTIONS, getEGridOptionsData);
}

function* getPropertyOptionsData(action: getPropertyOptionsAction): any {
    const json = yield optionsApiData(`${api_prefix}/property/`, action);
    let agencyOptions = yield getApiData(`${api_prefix}/agencies/?limit=all`, action);
    agencyOptions = agencyOptions.results.map((elements: any) => ({
        label: elements.name,
        value: elements.id,
        country: elements.country
    }));
    if (json?.actions?.POST) {
        yield put(
            propertyOptionsRecieved({
                ...json.actions.POST,
                agencyOptions
            })
        );
    }
}

export function* getPropertyOptionsSaga() {
    yield takeLatest(GET_PROPERTY_OPTIONS, getPropertyOptionsData);
}

function* getMeterOptionsRequest(action: getMeterOptionsAction): any {
    const json = yield optionsApiData(`${api_prefix}/wastemeter/`, action);
    const typeUnitMap = yield getApiData(`${api_prefix}/property/type_unit_map/`, action);
    if (json?.actions?.POST) {
        yield put(meterOptionsRecieved({ ...json.actions.POST, typeUnitMap }));
    }
}

export function* getMeterOptionsSaga() {
    yield takeLatest(GET_METER_OPTIONS, getMeterOptionsRequest);
}

function* getWasteMeterOptionsRequest(action: getWasteMeterOptionsAction): any {
    const json = yield optionsApiData(`${api_prefix}/wastemeter/`, action);
    if (json?.actions?.POST) {
        yield put(wasteMeterOptionsRecieved(json.actions.POST));
    }
}

export function* getWasteMeterOptionsSaga() {
    yield takeLatest(GET_WASTEMETER_OPTIONS, getWasteMeterOptionsRequest);
}
