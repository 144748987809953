import { all, put, takeLatest } from 'redux-saga/effects';

import { api_prefix } from '../../config';
import { getApiData, optionsApiData, patchApiData, postApiData } from '../../CommonUtilities';
import {
    createNewInvoiceFeeSuccess,
    editInvoiceDataSuccess,
    getInvoiceDataSuccess,
    getInvoiceListData,
    getInvoiceListDataSuccess,
    getInvoiceOptionsDataSuccess
} from '../actions';
import {
    createNewInvoiceFeeAction,
    CREATE_NEW_INVOICE_FEE,
    editInvoicDatatAction,
    EDIT_INVOICE_DATA,
    getInvoicDatatAction,
    GET_INVOICE_DATA,
    GET_INVOICE_LIST,
    GET_INVOICE_OPTIONS,
    getInvoiceListAction
} from '../actions/Finances/types';
import i18n from 'i18next';
import { constantStrings } from '../../constantStrings';

function* createNewInvoiceFeeSaga(action: createNewInvoiceFeeAction): any {
    const json = yield postApiData(
        `${api_prefix}/invoice/create_invoice/`,
        action,
        i18n.t(constantStrings.invoiceFeeCreated),
        true
    );
    if (!json.data) {
        yield put(createNewInvoiceFeeSuccess());
        yield put(getInvoiceListData({}, action.stream));
        action.history.push('/Finances');
    }
}

export function* newInvoiceFeeSaga() {
    yield all([takeLatest(CREATE_NEW_INVOICE_FEE, createNewInvoiceFeeSaga)]);
}

function* getInvoiceListSaga(action: getInvoiceListAction): any {
    const json = yield getApiData(`${api_prefix}/invoice/?stream=${action.stream}`, action);
    if (json && json.results) {
        yield put(getInvoiceListDataSuccess(json, action.appendData));
    }
}

export function* getInvoiceSaga() {
    yield all([takeLatest(GET_INVOICE_LIST, getInvoiceListSaga)]);
}

function* getInvoiceDataSaga(action: getInvoicDatatAction): any {
    const json = yield getApiData(`${api_prefix}/invoice/${action.id}/`, action);

    if (json && json.id) {
        yield put(getInvoiceDataSuccess(json));
    }
}

export function* getInvoiceDataSagas() {
    yield all([takeLatest(GET_INVOICE_DATA, getInvoiceDataSaga)]);
}

function* editInvoiceDataSaga(action: editInvoicDatatAction): any {
    const json = yield patchApiData(
        `${api_prefix}/invoice/${action.id}/`,
        action,
        i18n.t(constantStrings.invoiceFeeUpdated),
        true
    );
    let params: any = {
        ordering: 'id'
    };
    if (json && json.id) {
        yield put(editInvoiceDataSuccess());
        yield put(getInvoiceListData(params, action.stream));
        action.history.push('/Finances');
    }
}

export function* editInvoiceDataSagas() {
    yield all([takeLatest(EDIT_INVOICE_DATA, editInvoiceDataSaga)]);
}

function* getInvoiceOptionsSaga(action: createNewInvoiceFeeAction): any {
    const json = yield optionsApiData(`${api_prefix}/invoice/`, action);
    if (json && json.name && json.actions && json.actions.POST) {
        yield put(getInvoiceOptionsDataSuccess(json.actions.POST));
    }
}

export function* getInvoiceOptionsSagas() {
    yield all([takeLatest(GET_INVOICE_OPTIONS, getInvoiceOptionsSaga)]);
}
