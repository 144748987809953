import React, { useState, useEffect, useRef } from 'react';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    useExpanded
} from 'react-table';
import { CustomTable } from './styles';
import { CustomCheckBox } from '../styles';
import images from '../../../assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderClosed } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import FormDateTimePicker from '../FormElements/FormDateAndTimePicker';
import FormInput from '../FormElements/FormInput';
import FormSelect from '../FormElements/FormSelect';
import { useFormContext } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import IBILoader from '../../UIComponents/IBILoader';
import { TableFilterComponent } from './TableFilterComponent';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';
import GenericHeadingText from '../../UIComponents/GenericHeading';
import { getCommaSeparatedString } from '../../../CommonUtilities';
import { constantStrings } from '../../../constantStrings';
import i18n from 'i18next';
import { ProfileIconLoader } from '../../styles';

const getStickingDirection = (className: string) => {
    if (className === 'sticky-left') return 'left';
    if (className === 'sticky-right') return 'right';
    return null;
};

const TableRowsList = ({
    rows,
    visibleColumns,
    noDataText,
    prepareRow,
    redirectMethod,
    renderRowSubComponent,
    columnWidths
}: any) => {
    if (rows.length === 0)
        return (
            <tr>
                <td colSpan={visibleColumns.length}>
                    <GenericHeadingText title={noDataText} />
                </td>
            </tr>
        );

    return rows.map((row: any, i: any) => {
        prepareRow(row);
        return (
            <>
                <tr
                    {...row.getRowProps()}
                    onClick={(e: any) => redirectMethod(row, e)}
                    onContextMenu={(e) => {
                        redirectMethod(row, e, true);
                    }}
                >
                    {row.cells.map((cell: any) => {
                        const direction = getStickingDirection(cell.column.headerClassname);
                        return (
                            <td
                                {...cell.getCellProps()}
                                title={cell.value}
                                className={cell.column.headerClassname}
                                style={
                                    direction
                                        ? {
                                              [direction]: `${
                                                  columnWidths[direction][cell.column.id]
                                              }px`
                                          }
                                        : {}
                                }
                            >
                                {cell.column.isFolder && (
                                    <FontAwesomeIcon icon={faFolderClosed} className='pe-3' />
                                )}
                                {cell.column.isFile && (
                                    <FontAwesomeIcon icon={faFile} className='pe-3' />
                                )}
                                {cell.render('Cell')}
                            </td>
                        );
                    })}
                </tr>
                {row.isExpanded ? (
                    <tr>
                        <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                    </tr>
                ) : null}
            </>
        );
    });
};

export const EditableCell = ({
    value: initialValue,
    row: { index, original },
    column: {
        id,
        isNonEditableColumn,
        isAmount,
        isDate,
        options,
        autoFocus,
        renderFooterComponent
    },
    updateMyData,
    editableRowIndex,
    isEditable,
    useFormInput,
    isInvoice
}: // This is a custom function that we supplied to our table instance
any) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);
    const methods = useFormContext();
    const onChange = (e: any) => {
        let value = e.target.value;

        if (isAmount) {
            value = getCommaSeparatedString(e.target.value);
        }
        setValue(value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue ?? '');
    }, [initialValue]);

    let returnComponent: any = null;

    if (
        ((index === editableRowIndex && !isNonEditableColumn) ||
            (isEditable && !isNonEditableColumn && !original.isReadOnly)) &&
        !(original?.payment_status === 'Paid' || original?.payment_status === 'Verifying Payment')
    ) {
        if (isInvoice) {
            returnComponent = (
                <input
                    className='bg-transparent'
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    title={value}
                />
            );
        } else if (typeof isDate === 'function' ? isDate(original) : isDate) {
            returnComponent = (
                <FormDateTimePicker
                    name={id}
                    className={''}
                    value={value}
                    placeHolderText={i18n.t(constantStrings.datePlaceholder)}
                    autoFocus={autoFocus}
                />
            );
        } else if (options) {
            returnComponent = (
                <>
                    <FormSelect
                        displayName={''}
                        className={''}
                        name={id}
                        options={typeof options === 'function' ? options(original) : options}
                    />
                </>
            );
        } else {
            returnComponent = !useFormInput ? (
                <>
                    <input
                        type='text'
                        name={id}
                        className=' bg-transparent'
                        value={isAmount ? getCommaSeparatedString(value) : value}
                        onChange={onChange}
                        onBlur={onBlur}
                    />
                </>
            ) : (
                <FormInput name={id} type='text' asterisk={false} autoFocus={!!autoFocus} />
            );
        }
    } else {
        returnComponent = <div>{typeof value === 'object' ? value?.toString() : value}</div>;
    }
    if (renderFooterComponent) {
        const currentValue = methods.watch(id);
        returnComponent = (
            <div className={'d-flex flex-column'}>
                {returnComponent}{' '}
                {renderFooterComponent({
                    row: { original, editableRowIndex, index },
                    currentValue: currentValue?.value
                })}
            </div>
        );
    }
    return returnComponent;
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Cell: EditableCell
};

const TableContainer = ({
    columns,
    data,
    getRowId,
    rowClick,
    path,
    history,
    pageSize,
    getNextSetOfData,
    totalCount,
    children,
    isBorderNone,
    checkBox,
    setSelectedRows,
    initialSelectedRows,
    setSelectedRowsData,
    getSelectedRowsData,
    updateMyData,
    actualTableCount,
    isEditable,
    hiddenColumns,
    isInvoice,
    id,
    renderRowSubComponent,
    useFormInput,
    expandedRow,
    disableSort,
    keys,
    filterData,
    setFilterData,
    showHeaderCheckBox = false,
    fixedHeaderCheckboxColumn = false,
    noDataText,
    handleRightClick,
    notificationTable = false,
    hasMoreData = false,
    truncatedText = true
}: any) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [columnHeader, setColumnHeader] = useState('');
    const [editableRowIndex, setEditableRowIndex] = useState(null);
    const [sortState, setSortState] = useState<string>('');
    const [tableData, setTableData] = useState<any>(data);
    const [activeFilter, setActiveFilter] = useState<any>({ ...filterData });
    const [columnWidths, setColumnWidths] = useState<any>({ left: [], right: [] });
    const headerRefs = useRef<any>([]);
    const { t } = useTranslation();
    const [hasMore, setHasMore] = useState(hasMoreData || data.length > (pageSize || 10));

    const fetchMoreData = () => {
        if (hasMore) {
            if (notificationTable) return getNextSetOfData();
            return getNextSetOfData && getNextSetOfData(data.length);
        }
    };

    useEffect(() => {
        setTableData(data);
        if (notificationTable) setHasMore(hasMoreData);
        else {
            setHasMore(actualTableCount ? actualTableCount < totalCount : data.length < totalCount);
        }
    }, [actualTableCount, data, totalCount, hasMoreData, notificationTable]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        //@ts-ignore
        selectedFlatRows,
        //@ts-ignore
        state: { selectedRowIds, sortBy },
        visibleColumns,
        //@ts-ignore
        toggleRowExpanded,
        //@ts-ignore
        setSortBy
    } = useTable(
        {
            columns,
            defaultColumn,
            data: tableData,
            initialState: {
                //@ts-ignore
                selectedRowIds: initialSelectedRows ? initialSelectedRows : {},
                hiddenColumns: hiddenColumns || []
            },
            getRowId: getRowId || ((row, index) => index),
            updateMyData,
            editableRowIndex,
            setEditableRowIndex,
            isEditable,
            isInvoice,
            useFormInput,
            disableSort,
            autoResetSortBy: false
        },
        // hook for sorting
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        useRowSelect,
        (hooks) => {
            checkBox &&
                hooks.visibleColumns.push((columns: any) => [
                    {
                        id: 'selection',

                        Header: ({
                            getToggleAllRowsSelectedProps
                        }: {
                            getToggleAllRowsSelectedProps: any;
                        }) => (
                            <div>
                                {showHeaderCheckBox && (
                                    <CustomCheckBox>
                                        <input
                                            type='checkbox'
                                            {...getToggleAllRowsSelectedProps()}
                                        />
                                        <span className='duplicate-checkbox'></span>
                                    </CustomCheckBox>
                                )}
                            </div>
                        ),
                        headerClassname: fixedHeaderCheckboxColumn ? 'sticky-left' : '',
                        Filter: false,
                        Cell: ({ row }: { row: any }) => (
                            <div>
                                <CustomCheckBox>
                                    <input type='checkbox' {...row.getToggleRowSelectedProps()} />
                                    <span className='duplicate-checkbox'></span>
                                </CustomCheckBox>
                            </div>
                        )
                    },
                    ...columns
                ]);
        }
    );

    useEffect(() => {
        const widths: { left: number[]; right: number[] } = { left: [], right: [] };
        headerRefs.current.forEach((ref: any, index: number) => {
            const columnName = ref.getAttribute('name');
            if (ref.className.includes('sticky-left')) {
                const sumPrevStickyWidths = headerRefs.current
                    .slice(0, index)
                    .reduce(
                        (acc: number, prevRef: any) =>
                            prevRef.className.includes('sticky-left')
                                ? acc + prevRef.offsetWidth
                                : acc,
                        0
                    );
                widths.left[columnName] = sumPrevStickyWidths;
            } else if (ref.className.includes('sticky-right')) {
                const sumNextStickyWidths = headerRefs.current
                    .slice(index + 1)
                    .reduce(
                        (acc: number, nextRef: any) =>
                            nextRef.className.includes('sticky-right')
                                ? acc + nextRef.offsetWidth
                                : acc,
                        0
                    );
                widths.right[columnName] = sumNextStickyWidths;
            }
        });
        setColumnWidths(widths);
    }, [headerGroups]);

    useEffect(() => {
        getSelectedRowsData && setSelectedRows && setSelectedRows(selectedRowIds);
    }, [selectedRowIds, setSelectedRows, getSelectedRowsData]);

    useEffect(() => {
        if (getSelectedRowsData && setSelectedRowsData) {
            setSelectedRowsData(selectedFlatRows);
        }
        // eslint-disable-next-line
    }, [selectedFlatRows.length, setSelectedRowsData]);

    useEffect(() => {
        if (toggleRowExpanded) {
            rows.forEach((row: any) => {
                if (localStorage.getItem('editedMeter')) {
                    const editedMeter = localStorage.getItem('editedMeter');
                    //@ts-ignore
                    if (row.original.id === parseInt(editedMeter)) {
                        toggleRowExpanded(row.id, true);
                        localStorage.removeItem('editedMeter');
                    }
                }
            });
        }
    }, [toggleRowExpanded, expandedRow, rows]);

    const generateSortingIndicator = (column: any) => {
        const { Filter } = column;
        const filterKey = Filter?.filterKey;

        if (Filter?.isLoading) {
            return <ProfileIconLoader className='fas fa-spinner fa-spin fa-lg' />;
        }

        const isFilterActive = !activeFilter[filterKey?.startKey ?? filterKey];

        const sortImage = isFilterActive ? 'FilterImg' : 'Funnel';

        return <img src={images[sortImage]} alt={`${t(constantStrings.sort)}`} />;
    };

    const redirectMethod = (row: any, e: any, rightClick = false) => {
        let payload: any = {};
        if (id) {
            payload = {
                data: row.original,
                id: id
            };
        } else {
            payload = row.original;
        }
        if (rightClick && handleRightClick) handleRightClick(payload);
        else {
            if (rowClick) rowClick(payload);
            if (e.target.localName === 'div' || e.target.localName === 'td') {
                path && history && history.push(path, payload);
            }
        }
    };

    return (
        <CustomTable
            id={'table-responsive' + keys}
            className='table-responsive'
            isBorderNone={isBorderNone}
            truncatedText={rows.length ? truncatedText : false}
        >
            <InfiniteScroll
                dataLength={rows.length}
                next={fetchMoreData}
                loader={<IBILoader />}
                hasMore={hasMore}
                scrollableTarget={'table-responsive' + keys}
                style={{ overflow: 'inherit' }}
                onScroll={() => setShowOverlay(false)}
            >
                <table {...getTableProps()}>
                    {children}
                    <thead>
                        {headerGroups.map((headerGroup: any) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any, index: number) => {
                                    // addition to column: isSorted, isSortedDesc, getSortByToggleProps
                                    const {
                                        render,
                                        getHeaderProps,
                                        isSorted,
                                        isSortedDesc,
                                        getSortByToggleProps,
                                        showFilter = false,
                                        id,
                                        Filter,
                                        headerClassname = ''
                                    } = column;
                                    const extraClass = isSorted
                                        ? isSortedDesc
                                            ? 'desc'
                                            : 'asc'
                                        : '';
                                    const direction = getStickingDirection(column.headerClassname);
                                    if (!disableSort) {
                                        return (
                                            <th
                                                className={`${extraClass} ${headerClassname}`}
                                                // getHeaderProps now receives a function
                                                {...getHeaderProps(getSortByToggleProps())}
                                                ref={(el) => (headerRefs.current[index] = el)}
                                                style={
                                                    direction
                                                        ? {
                                                              [direction]: `${
                                                                  columnWidths[direction][column.id]
                                                              }px`
                                                          }
                                                        : {}
                                                }
                                                name={column.id}
                                            >
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <b>{render('Header')}</b>
                                                    {showFilter && (
                                                        <b
                                                            data-tip
                                                            data-for={'ps-2 filter'}
                                                            data-event={'click'}
                                                        >
                                                            {generateSortingIndicator(column)}
                                                        </b>
                                                    )}
                                                </div>
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <th
                                                className={`${extraClass} ${headerClassname}`}
                                                // getHeaderProps now receives a function
                                                {...getHeaderProps()}
                                                ref={(el) => (headerRefs.current[index] = el)}
                                                style={
                                                    direction
                                                        ? {
                                                              [direction]: `${
                                                                  columnWidths[direction][column.id]
                                                              }px`
                                                          }
                                                        : {}
                                                }
                                                name={column.id}
                                                onClick={() =>
                                                    !column.Filter?.isLoading &&
                                                    setColumnHeader(column.Header)
                                                }
                                            >
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <b>{render('Header')}</b>
                                                    {Filter && (
                                                        <>
                                                            <OverlayTrigger
                                                                key={column.Header}
                                                                trigger='click'
                                                                placement='bottom'
                                                                rootClose
                                                                show={
                                                                    columnHeader ===
                                                                        column.Header && showOverlay
                                                                }
                                                                overlay={
                                                                    <Popover>
                                                                        <Popover.Body className='p-0'>
                                                                            <TableFilterComponent
                                                                                id={id}
                                                                                filterValues={
                                                                                    Filter
                                                                                }
                                                                                toggleSortBy={
                                                                                    column.toggleSortBy
                                                                                }
                                                                                sortBy={sortBy}
                                                                                setSortBy={
                                                                                    setSortBy
                                                                                }
                                                                                setShowOverlay={
                                                                                    setShowOverlay
                                                                                }
                                                                                sortState={
                                                                                    sortState
                                                                                }
                                                                                setSortState={
                                                                                    setSortState
                                                                                }
                                                                                activeFilter={
                                                                                    activeFilter
                                                                                }
                                                                                setActiveFilter={
                                                                                    setActiveFilter
                                                                                }
                                                                                filterData={
                                                                                    filterData
                                                                                }
                                                                                setFilterData={
                                                                                    setFilterData
                                                                                }
                                                                            />
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                }
                                                                onToggle={() =>
                                                                    setShowOverlay(
                                                                        columnHeader !==
                                                                            column.Header
                                                                    )
                                                                }
                                                            >
                                                                <b
                                                                    onClick={() =>
                                                                        setShowOverlay(true)
                                                                    }
                                                                >
                                                                    {generateSortingIndicator(
                                                                        column
                                                                    )}
                                                                </b>
                                                            </OverlayTrigger>
                                                        </>
                                                    )}
                                                </div>
                                            </th>
                                        );
                                    }
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        <TableRowsList
                            columnWidths={columnWidths}
                            rows={rows}
                            visibleColumns={visibleColumns}
                            noDataText={noDataText}
                            prepareRow={prepareRow}
                            redirectMethod={redirectMethod}
                            renderRowSubComponent={renderRowSubComponent}
                        />
                    </tbody>
                </table>
            </InfiniteScroll>
        </CustomTable>
    );
};

export default TableContainer;
