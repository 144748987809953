import { showPopUp } from './CommonUtilities';
import { ENVIRONMENT, BASE_URL, ENABLE_REDUX_LOGGER } from './local_settings';

const api_prefix: string = '/api/v1';
const minTableRowHeight: number = 5;
let environment: string = ENVIRONMENT;
let showReduxLogger: boolean = !!ENABLE_REDUX_LOGGER;
let baseHTTPURL: string = BASE_URL;
let isTokenBasedAuthentication = false;
let apiToken = '';

const { NODE_ENV } = process.env;
if (NODE_ENV === 'development') {
    environment = 'LOCAL';
    showReduxLogger = true;
    baseHTTPURL = 'https://boma-canada-aws.ibigroup.in/';
}

if (environment === 'LOCAL') {
    const developmentEnvironment = NODE_ENV === 'development';

    try {
        if (developmentEnvironment) {
            const localConfig = require('./localServersConfig').default;

            if (localConfig) {
                isTokenBasedAuthentication = true;
                apiToken = localConfig.serverConfig.token;
                showReduxLogger = localConfig.showReduxLogger ?? true;
            } else {
                showPopUp({
                    titleText: 'Unable to find the specified server in local servers config',
                    type: 'error'
                });
            }
        }
    } catch (e) {
        showPopUp({
            titleText: 'Please include local servers config file',
            type: 'error'
        });
    }
} else {
    baseHTTPURL = `${window.location.protocol}//${window.location.host}`;
}

export {
    environment,
    baseHTTPURL,
    minTableRowHeight,
    api_prefix,
    showReduxLogger,
    isTokenBasedAuthentication,
    apiToken
};
