import i18n from 'i18next';
import { ValueLabelType } from './types';
import { constantStrings } from '../../constantStrings';

export const certificationLevelList: ValueLabelType[] = [
    { value: 'Platinum', label: i18n.t(constantStrings.platinum) },
    { value: 'Gold', label: i18n.t(constantStrings.gold) },
    { value: 'Silver', label: i18n.t(constantStrings.silver) },
    { value: 'Bronze', label: i18n.t(constantStrings.bronze) },
    { value: 'Certified', label: i18n.t(constantStrings.certified) }
];
export const assetTypeList: ValueLabelType[] = [
    { value: 'Healthcare', label: i18n.t(constantStrings.healthcare) },
    { value: 'Open Air Retail', label: i18n.t(constantStrings.openAirRetail) },
    {
        value: 'Multi Unit Residential Buildings',
        label: i18n.t(constantStrings.multiUnitResidentialBuildings)
    },
    { value: 'Universal', label: i18n.t(constantStrings.universal) },
    { value: 'Light Industrial', label: i18n.t(constantStrings.lightIndustrial) },
    { value: 'Enclosed Shopping Center', label: i18n.t(constantStrings.enclosedShoppingCenter) },
    { value: 'Office', label: i18n.t(constantStrings.office) }
].sort((current: any, prev: any) => current.label.localeCompare(prev.label));

export const colors: Record<string, string> = {
    Healthcare: '#A9C4E5',
    'Multi Unit Residential Buildings': '#546AAA',
    Universal: '#388968',
    'Light Industrial': '#A65A88',
    'Enclosed Shopping Center': '#8884d8',
    Office: '#F0BA61',
    'Open Air Retail': '#DFACD2'
};

export const START_YEAR_FOR_CERTIFICATION_AWARDED = 2019;
