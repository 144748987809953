import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import images from '../../../assets';
import { constantStrings } from '../../../constantStrings';
import { TopHeaderBar } from '../../styles';

const HeaderComponent = () => {
    const { t } = useTranslation();
    const multiTenantDataArray = useSelector((state: any) => state.Login.multiTenantData);
    const multiTenantData = multiTenantDataArray && multiTenantDataArray[0];

    return (
        <TopHeaderBar className='px-3 px-sm-5'>
            <div className='py-2 logo pe-3 me-auto'>
                {multiTenantData?.company_logo ? (
                    <img
                        className={'small-profile-icon'}
                        src={multiTenantData?.company_logo}
                        alt={`${t(constantStrings.icon)}`}
                    />
                ) : (
                    <img
                        src={images['BuildingIcon']}
                        className={'small-profile-icon'}
                        alt={`${t(constantStrings.icon)}`}
                    />
                )}
            </div>
        </TopHeaderBar>
    );
};

export default HeaderComponent;
