import React from 'react';
import PieCharts from '../../../../Recharts/PieCharts';
import { FormHeading, FormPrimaryContainer } from '../../../../styles';
import GenericHeadingText from '../../../../UIComponents/GenericHeading';
import { useTranslation } from 'react-i18next';
import { constantStrings } from '../../../../../constantStrings';

const PropertiesPertypeComponent = ({ filteredBuildingData }: any) => {
    const { t } = useTranslation();

    const propertiesPerTypeData = filteredBuildingData.buildingData
        .reduce((acc: any, data: any) => {
            const assetName = data.assetTypeName;

            let assetObject = acc.find((item: any) => item.name === assetName);

            if (!assetObject) {
                assetObject = {
                    name: assetName,
                    value: 0
                };
                acc.push(assetObject);
            }

            assetObject['value']++;

            return acc;
        }, [])
        .sort((a: any, b: any) => a.name - b.name);

    const showData = () => {
        if (!propertiesPerTypeData.length)
            return (
                <GenericHeadingText
                    title={t(constantStrings.noInformationAvailable)}
                    className='col-3 mb-1'
                />
            );

        return (
            <PieCharts
                pieChartData={propertiesPerTypeData}
                innerRadius={60}
                outerRadius={110}
                containerHeight={450}
            />
        );
    };

    return (
        <FormPrimaryContainer className='col-12 flex-fill' border={true}>
            <FormHeading> {t(constantStrings.propertiesPerType)}</FormHeading>
            {showData()}
        </FormPrimaryContainer>
    );
};

export default PropertiesPertypeComponent;
