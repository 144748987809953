import styled from 'styled-components/macro';
import { FormElementProps, VariantEntryTextArea } from './helpers';
import { theme } from '../../../theme';
import { VariantLableHeading, VariantEntryInput } from './helpers';
import {
    customCheckBoxDimension,
    customRadioDimension,
    entryInputHeight,
    eyeIconWidth
} from '../../../variables';
import { displayFlex, flexCenter, fontBold, fontMedium, textNormal } from '../../styles';
import { fontSize_SM, fontSize_XS, fontSize_BASE, fontSize_LG } from '../../customFonts';

interface FontIconInputProps {
    isThemeColor?: boolean;
    isResetPage?: boolean;
    isLeftSide?: boolean;
}

interface EyeFontIconProps {
    isLoginPage?: boolean;
}

interface DisabledProps {
    isDisabled?: boolean;
}

interface SelectContainerProps {
    isEmpty?: boolean;
    maxHeight?: string;
}

interface CheckBoxOptionsProps {
    optionsStyle?: boolean;
}

interface TextAreaProps {
    minHeight?: string;
}

export const CustomElement = styled.label`
    display: flex;
    align-items: center;
    margin: 5px 0;

    input[type='radio']:after {
        width: 9px;
        height: 9px;
        border-radius: 15px;
        bottom: 4.3px;
        left: 2px;
        position: relative;
        background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid white;
    }

    input[type='radio']:checked:after {
        background-color: ${(props) => props.theme.primary.THEME};
    }

    b {
        padding-left: 0.5rem;
        color: ${(props: FormElementProps): string => {
            if (props.disabled) {
                return '#CCCCCC';
            }

            return '#666666';
        }};
        ${fontSize_SM};
        font-weight: 400;

        &:hover {
            color: ${(props: FormElementProps): string => {
                if (props.disabled) {
                    return '#CCCCCC';
                }

                return '#1A1A1A';
            }};
        }
    }
`;

export const EntryInput = styled(VariantEntryInput)`
    width: 100%;
    min-height: ${entryInputHeight};
    box-shadow: none;
    ${fontSize_SM};
    ${fontMedium}
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: ${(props): string => props.theme.standard.DARK_BLACK};

    &::placeholder {
        color: ${(props) => props.theme.primary.inputPlaceholderColor};
        opacity: 1;
    }

    //Remove controls from Firefox
    &[type='number'] {
        -moz-appearance: textfield;
    }

    @media (-webkit-min-device-pixel-ratio: 1.2) {
        height: calc(${entryInputHeight} / 1.2);
    }

    &[type='number']:hover,
    &[type='number']:focus,
    &[type='number']:focus-visible {
        -moz-appearance: auto;
    }
`;

export const FontIconInInput = styled.i<FontIconInputProps>`
    position: absolute;
    padding: 5px 15px;
    ${fontSize_BASE}
    left: ${(props) => (props.isLeftSide ? '0' : 'unset')};
    right: ${(props) => (props.isLeftSide ? 'unset' : '0')};
    color: ${(props) => (props.isThemeColor ? theme.primary.THEME : theme.primary.SECONDARY_THEME)};

    &:hover {
        color: ${(props: any) => props.theme.primary.THEME};
    }

    &:focus-visible {
        outline: none;
        color: ${(props) => props.theme.primary.THEME};
        ${fontSize_LG};
    }
`;

export const EyeFontIcon = styled(FontIconInInput)<EyeFontIconProps>`
    z-index: 1000;
    top: 50%;
    transform: translateY(-50%);
    ${flexCenter};
    right: 0;
    left: unset;
    cursor: pointer;
    img {
        width: ${eyeIconWidth};
    }

    @media (-webkit-min-device-pixel-ratio: 1.2) {
        img {
            width: calc(${eyeIconWidth} / 1.2);
        }
    }
`;

export const ValidationIcon = styled(EyeFontIcon)<FontIconInputProps>`
    color: none;
    transform: translate(0%, -50%);
    top: 50%;
    bottom: 50%;
    cursor: none;
`;

export const LabelHeading = styled(VariantLableHeading)`
    margin: 0px;
    color: ${(props) => props.theme.light_primary.LIGHT_QUINARY};
    ${fontSize_SM};

    .info {
        cursor: text;
    }
`;

export const CheckboxOptions = styled.label<CheckBoxOptionsProps>`
    color: ${(props) => props.optionsStyle && props.theme.light_primary.LIGHT_QUINARY};
`;

export const ErrorText = styled.div`
    ${fontSize_XS};
    color: red;
`;

export const SearchFilterBox = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    input:focus + .searchIcon {
        color: ${(props) => props.theme.primary.THEME};
    }
`;

export const FormInputOverlapBtn = styled.div`
    position: absolute;
    right: 10px;
    color: ${(props: any) => props.theme.primary.THEME};
    font-weight: 500;
    cursor: pointer;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    top: 50%;
    transform: translateY(-50%);
    ${fontSize_SM};
`;

export const FormTextInputOverlapBtn = styled.div`
    position: relative;
    right: 0;
    color: ${(props: any) => props.theme.primary.THEME};
    font-weight: 500;
    cursor: pointer;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    ${fontSize_SM};
`;

export const SelectContainer = styled.div<SelectContainerProps>`
    .react-select__value-container {
        max-height: ${(props) => props.maxHeight};
    }

    .react-multi-select__control {
        box-shadow: none;

        &:hover,
        &:focus,
        &:visited,
        &:focus-visible {
            border: 1px solid ${(props) => props.theme.primary.THEME};
            outline: none;
        }
    }

    .react-select__control {
        ${fontSize_SM}
        border-color: ${(props) => props.isEmpty && props.theme.danger.DARK_DANGER};
        box-shadow: ${(props) => props.isEmpty && 'none'};
    }
`;

export const EntryTextArea = styled(VariantEntryTextArea)<TextAreaProps>`
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    min-height: ${(props) => props.minHeight || '120px'}; /* Set a minimum height */
`;

export const CustomCheckBox = styled.label<DisabledProps>`
    display: block;
    position: relative;
    cursor: ${(props: DisabledProps): string => (props.isDisabled ? 'not-allowed' : 'pointer')};
    margin: 2px 0px;
    height: ${customCheckBoxDimension};
    width: ${customCheckBoxDimension};

    input[type='checkbox'] {
        visibility: hidden;
    }

    .disclaimer-checkbox {
        visibility: visible !important;
    }

    .duplicate-checkbox {
        position: absolute;
        top: 0;
        left: 0;
        height: ${customCheckBoxDimension};
        width: ${customCheckBoxDimension};
        border: 2px solid ${(props) => props.theme.light_primary.LIGHT_OCTONARY};
        background-color: ${(props: DisabledProps): string =>
            props.isDisabled ? theme.light_secondary.LIGHT_PRIMARY : theme.standard.WHITE};
        border-radius: 4px;
        opacity: ${(props: DisabledProps): string => (props.isDisabled ? '0.5' : '1')};

        &:hover {
            border-color: ${(props) => props.theme.primary.THEME};
        }
    }

    input:checked ~ .duplicate-checkbox {
        background-color: ${(props) => (props.isDisabled ? '#CCCCCC' : props.theme.primary.THEME)};
        border: 1px solid
            ${(props) =>
                props.isDisabled ? theme.standard.LIGHT_BLACK : props.theme.primary.THEME};
        opacity: 1;
    }

    .duplicate-checkbox:after {
        content: '';
        position: absolute;
        display: none;
    }

    input:checked ~ .duplicate-checkbox:after {
        display: block;
    }

    .duplicate-checkbox:after {
        left: 5px;
        bottom: 4px;
        width: 5px;
        height: 10px;
        border: solid ${(props) => theme.standard.WHITE};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
`;
export const EntryDate = styled.div<DisabledProps>`
    .react-datepicker-wrapper {
        ${displayFlex}
    }

    .react-datepicker-popper {
        z-index: 999;
    }

    .custom-multidatepicker {
        .rmdp-arrow-container {
            align-items: center;

            .rmdp-arrow {
                margin-top: 0;
            }
        }
    }

    .react-datepicker__navigation {
        transform: translate(0, 25%);
    }

    .react-datepicker__input-container,
    .rmdp-container {
        width: 100%;
        input,
        textarea {
            width: 100%;
            min-height: ${entryInputHeight};
            background-color: ${(props) =>
                props.isDisabled
                    ? props.theme.light_secondary.LIGHT_PRIMARY
                    : props.theme.standard.WHITE};
            border: 1px solid ${(props) => props.theme.light_primary.LIGHT_PRIMARY};
            border-radius: 6px;
            padding: 5px 10px;
            ${fontMedium}
            // margin: 5px 0;
      box-shadow: none;
            cursor: pointer;

            @media (-webkit-min-device-pixel-ratio: 1.2) {
                height: calc(${entryInputHeight} / 1.2);
            }

            &:hover,
            &:focus,
            &:visited,
            &:focus-visible {
                border: 1px solid ${(props) => props.theme.primary.THEME};
                outline: none;
            }
        }
        textarea {
            min-height: ${entryInputHeight};
        }
        input {
            padding-right: 40px;
        }

        input[placeholder] {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .react-datepicker-popper {
        z-index: 1001;
        position: fixed !important;
        transform: unset;
        inset: unset;
    }

    .react-datepicker {
        border: 1px solid ${(props): string => props.theme.primary.THEME};
        box-shadow: 0 3px 8px ${(props): string => props.theme.light_primary.LIGHT_PRIMARY};

        .react-datepicker__navigation-icon--previous::before,
        .react-datepicker__navigation-icon--next::before {
            border-color: ${(props): string => props.theme.standard.DARK_BLACK};
        }

        .react-datepicker__header--time {
            display: none;
        }

        .react-datepicker__header {
            background-color: ${(props): string => props.theme.standard.WHITE};
            border-bottom: 1px solid ${(props): string => props.theme.light_primary.LIGHT_PRIMARY};
            width: 100%;
            text-align: center;

            .react-datepicker__current-month,
            .react-datepicker-time__header,
            .react-datepicker-year-header {
                color: ${(props): string => props.theme.primary.THEME};
                ${fontSize_BASE}
                font-weight: 500;
            }

            .react-datepicker__day-name {
                color: ${(props): string => props.theme.primary.THEME};
                text-transform: uppercase;
                ${fontSize_SM}
                font-weight: 500;
                margin: 5px;
            }
        }

        .react-datepicker__day {
            margin: 5px;
        }

        .react-datepicker__month .react-datepicker__day {
            ${fontSize_SM}
            font-weight: 500;

            &:focus {
                outline: none;
            }
        }
        .react-datepicker__year-wrapper {
            justify-content: space-around;
        }
        .react-datepicker__year-text {
            width: auto;
            padding: 2px 15px;
        }
        .react-datepicker__year-text--selected,
        .react-datepicker__year-text:hover {
            color: ${(props): string => props.theme.standard.WHITE};
            background-color: ${(props): string => props.theme.primary.THEME};
            ${fontBold}
        }
        .react-datepicker__month .react-datepicker__day--keyboard-selected {
            background-color: ${(props): string => props.theme.primary.THEME};
            border-radius: 100%;
            color: ${(props): string => props.theme.standard.WHITE};
        }

        .react-datepicker__day--selected,
        .react-datepicker__day:hover {
            border-radius: 100%;
            color: ${(props): string => props.theme.standard.WHITE};
            background-color: ${(props): string => props.theme.primary.THEME};
        }
    }

    .react-datepicker__close-icon {
        right: 30px;
    }
    .react-datepicker__close-icon::after {
        content: '✕';
        background-color: ${(props): string => props.theme.primary.THEME};
    }
`;

export const CustomRadioElement = styled.div`
    display: flex;
    cursor: pointer;
    position: relative;

    input[type='radio'] {
        height: ${customRadioDimension};
        width: ${customRadioDimension};
        accent-color: ${(props) => props.theme.primary.THEME};
    }

    label {
        margin-left: 5px;
        margin-bottom: 0;
        color: ${(props: FormElementProps): string => {
            if (props.disabled) {
                return '#CCCCCC';
            }
            return '#666666';
        }};
        ${fontMedium};
        ${textNormal}
        cursor: pointer;
    }
`;

export const MultiValueWrapper = styled.div`
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .max-limit-msg {
        min-width: 100px;
        color: ${theme.danger.DARK_DANGER};
        margin-left: 0.25rem;
        margin-top: 0.25rem;
    }
`;
